import * as React from 'react';
import TableCell from '@mui/material/TableCell';
import TableRow from '@mui/material/TableRow';
import './Row.css';


const Row =({data})=> {
    return (
        <TableRow>
            {data.map((el, i) => <TableCell className='cellWidth' key={i}>{el}</TableCell>)}
        </TableRow>
    );
}


export default Row;
