import React, {useEffect, useState} from "react";
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import Paper from '@mui/material/Paper';
import axios from "axios";

import './App.css';
import {tableHeading} from '../../constants/tableHeading'
import Row from "../row/Row";
import Pagination from "../pagination/Pagination";

const App = () => {
    const [data, setData] = useState([])
    const [error, setError] = useState('')

    const [page, setPage] = useState(0)
    const [rowsPerPage, setRowsPerPage] = useState(10)
    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };
    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(parseInt(event.target.value, 10));
        setPage(0);
    };

    useEffect(() => {
        axios.get(process.env.REACT_APP_FETCH_DATA_LINK)
            .then(function (response) {
                setData(response.data.data)
            })
            .catch(function (e) {
                setError(e)
            });
    }, [])

    return (error ? `Data cannot be fetched: ${error}` : data.length ?
        <TableContainer component={Paper}>
            <Table sx={{minWidth: 300}} aria-label="simple table">
                <TableHead>
                    <Row data={tableHeading}/>
                </TableHead>
                <TableBody>
                    {data.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((row, i) => (
                        <Row data={Object.values(row)} key={i}/>))}

                    <Pagination
                        dataLength={data.length}
                        rowsPerPage={rowsPerPage}
                        page={page}
                        handleChangePage={handleChangePage}
                        handleChangeRowsPerPage={handleChangeRowsPerPage}
                    />
                </TableBody>
            </Table>
        </TableContainer>
            : <h3>Loading...</h3>
    );
}


export default App;
