import * as React from 'react';
import TableRow from '@mui/material/TableRow';
import {TablePagination} from "@mui/material";


const Pagination =({dataLength, rowsPerPage, page, handleChangePage, handleChangeRowsPerPage})=> {
    return (
        <TableRow>
            <TablePagination
                count={dataLength}
                rowsPerPage={rowsPerPage}
                page={page}
                onPageChange={handleChangePage}
                onRowsPerPageChange={handleChangeRowsPerPage}
                rowsPerPageOptions={[5, 10, 25, 50]}
                labelRowsPerPage={<span>Rows:</span>}
                labelDisplayedRows={({ page }) => {
                    return `Page: ${page + 1} / ${Math.ceil(dataLength / rowsPerPage)}`;
                }}
                backIconButtonProps={{
                    color: "secondary"
                }}
                nextIconButtonProps={{ color: "secondary" }}
                SelectProps={{
                    inputProps: {
                        "aria-label": "page number"
                    }
                }}
                showFirstButton={true}
                showLastButton={true}
            />
        </TableRow>
    );
}


export default Pagination;
